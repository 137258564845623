@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
    font-family: "Poppins", sans-serif;
    color:#474747;
  }

  td,th {
    text-align: left;
    padding: 10px 15px;
  }
  

  .homeTable td,.homeTable th {
    border: 1px solid #ECECEC;
  }